import * as React from 'react';

import { AppProps, SeoObject } from 'src/app/types';
import { ButtonTw, Localized } from 'src/app/components';

import { useLocalizedNavigation } from 'src/app/hooks';
import {
  AppPageLayout,
  AppPageLinks,
  customerHelpTopics,
  HelpTopicContainer,
  HelpTopicSearch,
} from 'src/app.consumer/components';

const seo: SeoObject = {
  title: 'Customer help page',
};

export const CustomerHelpPage = (props: AppProps) => {
  const { navigateLocalized } = useLocalizedNavigation();
  const onContactButtonClick = () => {
    navigateLocalized('/contact'); // TODO: eval create /contact/customer route with extra customer func call params
  };
  return (
    <AppPageLayout
      sidebar={<AppPageLinks />}
      subTitle={
        <Localized dictKey={'staticLocales:pages./help.pages./customer.subtitle'} />
      }
      title={
        <Localized dictKey={'staticLocales:pages./help.pages./customer.link label'} />
      }
      {...{ ...props, seo }}
    >
      <div>
        <HelpTopicSearch topics={customerHelpTopics} />
        <div className='pt-8 pb-2'>
          <Localized
            dictKey={'staticLocales:pages./help.pages./customer.browse-topics.title'}
          />
        </div>
        {customerHelpTopics?.map((topic) => {
          return (
            <div key={topic.id} >
              <HelpTopicContainer
                dictKey={'shop:/help/customer.topic'}
                topicData={topic}
              />
            </div>
          );
        }) || null}
        <div className='text-center'>
          <ButtonTw
            variant={'accent'}
            rounded
            size = 'lg'
            onClick={onContactButtonClick}
            className='mt-4 w-full md:w-auto md:min-w-[270px]'
          >
            <Localized
              dictKey={'staticLocales:pages./help.pages./customer.action.contact.label'}
            />
          </ButtonTw>
        </div>
      </div>
    </AppPageLayout>
  );
};
export default CustomerHelpPage;
